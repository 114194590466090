<template>
  <div class="modal-wrapper" :class="{open: isOpen}" v-on:click="onBackdropClick">
    <div class="modal" :class="{open: isOpen}" v-on:click.stop="onModalClick">
      <slot></slot>
    </div>
  </div>
</template>



<script>
import {Component, Vue} from "vue-property-decorator";

@Component({
  components: {},
  props:  {
    'isOpen': Boolean,
    'onBackdropClick': Function,
  }
})

export default class Modal extends Vue {
  onModalClick() {}
}
</script>

<style lang="scss">

.modal-wrapper {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 55px;
  background-color: hsla(0, 0, 0, 0);
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;
  z-index: 100;

  &.open {
    pointer-events: all;
    background-color: hsla(0, 0, 0, 0.7);

    .modal {
      margin: auto;
      transform: translateY(0);
    }
  }

  .modal {
    overflow: hidden;
    padding: 20px;
    position: fixed;
    height: 600px;
    background-color: white;
    margin: auto;
    transition: transform 0.2s;
    transform: translateY(100vh) scale(0.3);
    width: 975px;
    box-sizing: border-box;
    max-width: 80vw;
    max-height: 80vh;
    display: flex;
  }
}

</style>
