<template>
  <article ref="main" class="single-webinar">
    <section class="bar-green">
      <div class="main-limiter">
        <div class="main-wrapper margin-bottom-2">
          <div class="left-wrapper">
            <DoroImage simple :src="image(webinar.id, webinar.mainImage)" square />
          </div>
          <div class="right-wrapper">
            <div class="right-inner">
              <p class="webinar-date">
                {{calculatedDate}}
                <br/>
                {{calculatedTime}}
              </p>
              <H3 class="webinar-title">{{webinar.title}}</H3>
              <button v-if="webinar.upcoming" class="webinar-register-button" v-on:click="toggleModal" :disabled="!webinar.upcoming">
                Register
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <main class="main-limiter margin-bottom-6">
      <section>
        <el-row :gutter="20" class="margin-bottom-2">
          <el-col :xs="24" :span="12">
            <H4 class="webinar-summary">{{webinar.summary}}</H4>
          </el-col>
          <el-col :xs="20" :span="8" :push="2" class="calendar-col">
            <H4 class="calendar-title">Date and time</H4>
            <P class="calendar-date"> {{calculatedDateTime}}</P>
            <add-to-calendar :title="calendarTitle"
                             :start="webinar.dateTime"
                             :end="webinar.endDateTime"
                             :details="calendarDescription"
                             inline-template>
              <div>

                <google-calendar id="google-calendar" class="calendar-add">
                  <i class="fa fa-google"></i> Add to Google calendar
                </google-calendar>
              </div>
            </add-to-calendar>
          </el-col>
        </el-row>
      </section>
      <Node class="margin-bottom-5"/>
      <WebinarRegisterForm :webinar="webinar" v-if="webinar.upcoming" :on-success="submitSuccessInline" ref="registerFormInline" :omit-title="true"/>
    </main>
    <Modal :on-backdrop-click="toggleModal" :is-open="modalOpen">
      <WebinarRegisterForm :webinar="webinar" :on-success="submitSuccess" ref="registerForm"/>
      <div :class="{sent: modalShowSuccess, instant: noTransition}" class="sent-overlay">
        <FontAwesomeIcon icon="check" class="large-check"/>
        <p>
          You have successfully registered for the webinar.<br />
          Details will be sent to your email address.<br />
          See you soon!</p>
      </div>
    </Modal>
  </article>
</template>

<script>
import {Component, Vue, Prop} from "vue-property-decorator";
import DoroImage from "@/components/DoroImage";
import Modal from "../components/Modal";
import DoroButton from "../components/DoroButton";
import AddToCalendar from "vue-add-to-calendar";
import WebinarRegisterForm from "../components/WebinarRegisterForm";
import {library} from '@fortawesome/fontawesome-svg-core'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

library.add(faCheck)

@Component({
  components: {FontAwesomeIcon, WebinarRegisterForm, DoroButton, Modal, DoroImage, AddToCalendar},
  metaInfo() {
    return {
      title: 'Webinar - ' + this.webinar.title,
      meta: [
        {
          name: 'title',
          content: "AI REV - Webinar - " + this.webinar.title
        },
        {
          name: 'description',
          content: this.webinar.summary
        },
        {
          property: 'og:title',
          content: "AI REV - Webinar - " + this.webinar.title,
        },
        {
          property: 'og:image',
          content: "https://airev.us" + this.image(this.webinar.id, this.webinar.mainImage),
        },
        {
          property: 'og:description',
          content: this.webinar.summary,
        },
        {
          property: 'og:url',
          content: "https://airev.us/webinar/" + this.webinar.id,
        },
        {
          property: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          property: 'twitter:url',
          content: "https://airev.us/webinar/" + this.webinar.id,
        },
        {
          property: 'twitter:title',
          content: "AI REV - Webinar - " + this.webinar.title,
        },
        {
          property: 'twitter:description',
          content: this.webinar.summary,
        },
        {
          property: 'twitter:image',
          content: "https://airev.us" + this.image(this.webinar.id, this.webinar.mainImage),
        }
      ],
      script: [{
        type: 'application/ld+json',
        json: {
          "@context": "https://schema.org",
          "@type": "Event",
          "name": this.webinar.title,
          "description": this.webinar.summary,
          "image": "https://airev.us"+this.image(this.webinar.id, this.webinar.mainImage),
          "startDate": this.webinar.dateTime.toISOString(),
          "endDate": this.webinar.endDateTime.toISOString(),
          "eventStatus": "https://schema.org/EventScheduled",
          "eventAttendanceMode": "https://schema.org/OnlineEventAttendanceMode",
          "performer": {
            "@type": "Person",
            "name": this.webinar.performer,
          }
        }
      }]
    }
  },
  beforeMount() {
    this.$options.components.Node = this.webinar.content;
  }
})
export default class WebinarList extends Vue {
  modalOpen = false;
  modalShowSuccess = false;
  noTransition = false;

  submitSuccess() {
    this.modalOpen = true;
    this.modalShowSuccess = true;
  }
  submitSuccessInline() {
    this.toggleModal()

    this.noTransition = true;
    this.modalShowSuccess = true;
    this.$refs['registerFormInline'].resetForm()
  }
  image(webinarId, imageName) {return require(`@/assets/webinars/${webinarId}/${imageName}`)}
  resetModal() {
    this.modalShowSuccess = false;
    this.$refs['registerForm'].resetForm()
  }
  toggleModal() {
    this.noTransition = false;
    if (this.modalOpen) { // close
      document.querySelector('html').style.overflow = 'auto'
      if (this.modalShowSuccess) {
        setTimeout(this.resetModal, 500);
      }
    } else { // open
      this.resetModal()
      document.querySelector('html').style.overflow = 'hidden'
    }
    this.modalOpen = !this.modalOpen;
  }

  get calculatedDate() {
    return this.webinar.dateTime.toLocaleDateString('en-US', {year: 'numeric', month: 'short', day: 'numeric'})
  }

  get calculatedTime() {
    return this.webinar.dateTime.toLocaleTimeString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short'})
  }

  get calculatedDateTime() {
    return this.webinar.dateTime.toLocaleTimeString('en-US', {year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short'})
  }

  get calendarTitle() {
    return `${this.webinar.title} - AI REV`
  }

  get calendarDescription() {
    return `${this.webinar.summary}

--- --- --- --- ---

Webinar organized by AI REV.
Read more: https://airev.us/webinar/${this.webinar.id}`
  }

  @Prop() webinar;
}
</script>

<style lang="scss">
.single-webinar {
  .main-wrapper {
    display: flex;
    @media only screen and (max-width: 767px) {
      flex-direction: column;
    }
    & > * {
      flex: 1;
      width: 100%;
    }
  }

  .left-wrapper {
    line-height: 0;
  }

  .webinar-summary {
    font-weight: 500;
    font-size: 20px;
  }

  .consent {
    font-size: 11px;
    line-height: 13px;
  }

  .right-wrapper {

    .right-inner {
      margin: min(20px, 5vw) min(70px, 5vw);
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    display: flex;
    flex-direction: column;

    .webinar-date {
      margin: 0;
      font-weight: 500;
      font-size: 16px;
      text-transform: uppercase;
    }

    .webinar-title {
      margin: auto 0;
      font-weight: 700;
      font-size: 18px;
      text-align: left;
    }
  }

  .webinar-register-button {
    color: $AiRevGreen;
    height: 50px;
    border-radius: 25px;
    border: 0;
    cursor: pointer;
    padding: 0 50px;
    font-size: 1em;
    transition: opacity 0.1s;
    font-weight: bold;
    flex-shrink: 0;
    margin-top: auto;

    &:hover {
      opacity: 0.8;
    }

    &.webinar-register-button-form {
      background: $AiRevGreen;
      color: white;
      max-width: 350px;
      margin: auto auto 15px;
      padding: 0 80px;
    }
  }

  .bar-green {
    background: $AiRevGreen;
    color: white;

    h1, h2, h3 {
      color: white;
    }
  }

  h4 {
    font-weight: bold;
  }

  .calendar-col {
    margin: 1.33em 0;
    padding-left: 80px;
  }

  .calendar-title {
    font-weight: 400;
    font-size: 17px;
    margin: 0.5ch 0;
  }

  .calendar-date {
    font-weight: 300;
    font-size: 15px;
    margin: 0.5ch 0;
  }

  .sent-overlay {
    background-color: $AiRevGreen;
    position: absolute;
    padding: 25px;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    transform: translateX(100%);
    transition: transform 0.3s;
    z-index: 1;

    &.instant {
      transition: none;
    }

    &.sent {
      transform: translateX(0);
    }

    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      font-weight: 700;
      color: white;
      font-size: 18px;
    }
  }
}
</style>
