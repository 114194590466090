<template>
  <div class="center-content">
    <H2 v-if="!omitTitle">{{webinar.title}}</H2>
    <p class="hint-date" v-if="!omitTitle">
      Starts on {{calculatedDateTime}}
    </p>
    <p class="info" v-if="!omitTitle">Please fill out your personal information and click register</p>
    <label>
      <FontAwesomeIcon icon="user" class="over-input" />
      <input class="webinar-register-input" type="text" name="first-name" placeholder="First name*" ref="firstName"/>
    </label>
    <label>
      <FontAwesomeIcon icon="user" class="over-input" />
      <input class="webinar-register-input" type="text" name="last-name" placeholder="Last name*" ref="lastName"/>
    </label>
    <label>
      <FontAwesomeIcon icon="envelope" class="over-input" />
      <input class="webinar-register-input" type="email" name="email" placeholder="E-mail*" ref="email"/>
    </label>
    <div class="checkbox-wrapper">
      <input type="checkbox" ref="consentWebinar"/>
      <p class="consent">I hereby give consent for my personal data included in the form processed by AI REV LLC for the purposes of the webinar.*</p>
    </div>
    <div class="checkbox-wrapper">
      <input type="checkbox" ref="consentMarketing"/>
      <p class="consent">I hereby give consent for my personal data included in the form to be processed by AI REV LLC for marketing purposes. </p>
    </div>
    <p class="error-message">{{errorMessage|| '&nbsp;'}}</p>
    <button class="webinar-register-button webinar-register-button-form" v-on:click="tryRegister" :disabled="locked">
      Register for free!
    </button>
  </div>
</template>

<script>
import {Component, Prop, Vue} from "vue-property-decorator";
import DoroButton from "./DoroButton";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import axios from "axios";
import {library} from '@fortawesome/fontawesome-svg-core'
import { faUser, faEnvelope } from '@fortawesome/free-solid-svg-icons'
library.add(faUser)
library.add(faEnvelope)

@Component({
  components: {DoroButton, FontAwesomeIcon},
})
export default class WebinarRegisterForm extends Vue {
  errorMessage = "";
  locked = false;
  sent = false;

  resetForm() {
    this.errorMessage = "";
    this.sent = false;
    this.$refs['firstName'].value = "";
    this.$refs['lastName'].value = "";
    this.$refs['email'].value = "";
    this.$refs['consentWebinar'].checked = false;
    this.$refs['consentMarketing'].checked = false;
  }
  tryRegister() {
    const firstName = this.$refs['firstName'].value;
    const lastName = this.$refs['lastName'].value;
    const email = this.$refs['email'].value;
    const consentWebinar = this.$refs['consentWebinar'].checked;
    const consentMarketing = this.$refs['consentMarketing'].checked;
    const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

    if (firstName?.length && lastName?.length && email) {
      if (!consentWebinar) {
        this.errorMessage = "Consent for webinar-related data processing is required."
        return;
      }
      if (!emailRegex.test(email)) {
        this.errorMessage = "This doesn't seam to be a valid email."
        return;
      }

      this.locked = true;
      axios.post('https://api.airev.us/register', {
        firstName, lastName, email, consentWebinar, consentMarketing, webinarId: this.webinar.id
      }, {
        headers: {
          "Referrer-Policy": "no-referrer"
        }
      }).then(() => {
        this.onSuccess()
        this.sent = true;
        this.locked = false;
      }).catch((err) => {
        this.errorMessage = err.message;
        this.locked = false;
      })
    } else {
      this.errorMessage = "Please fill in all required fields."
    }
  }

  get calculatedDateTime() {
    return this.webinar.dateTime.toLocaleTimeString('en-US', {year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short'})
  }

  @Prop() webinar;
  @Prop() onSuccess;
  @Prop() omitTitle;
}
</script>

<style lang="scss">
.error-message {
  color: red;
}

.center-content {
  display: flex;
  flex-direction: column;
  text-align: center;
  flex-grow: 1;
  overflow-y: auto;

  h2 {
    margin: 0.5ch 0;
    font-size: 18px;
    font-weight: 400;
  }

  p.info {
    font-size: 16px;
    font-weight: 400;
  }

  p.hint-date {
    color: #737373;
    font-size: 14px;
    font-weight: 400;
  }


}

.webinar-register-input {
  display: inline;
  max-width: 600px;
  width: 80%;
  height: 40px;
  margin: 1ch auto;
  border-radius: 20px;
  border: 2px solid $AiRevGreen;
  padding-left: 40px;

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 2px white, 0 0 0 4px $AiRevGreen;

  }
}

.over-input {
  width: 20px;
  margin-left: 10px;
  margin-right: -30px;
  z-index: 1;
  position: relative;
}

.checkbox-wrapper {
  max-width: 600px;
  width: 80%;
  display: flex;
  margin: 0 auto;
  align-items: center;
  text-align: left;

  p {
    margin: 0.3ch;
  }

}
.large-check {
  font-size: 130px;
  color: white;
  border: 10px solid white;
  border-radius: 50%;
  padding: min(60px, 2vw);
}
</style>
